import React from 'react'

import {
  Header,
  HeaderATMT,
  HeaderBackground,
  HeaderButton,
  HeaderSearch,
  Paragraph
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Link as GatsbyLink } from '../../components/Link'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { HeaderAsiointiHTMLExample } from '../../examples/html/HeaderAsiointiHTMLExample'
import { HeaderKayttoohjeetHTMLExample } from '../../examples/html/HeaderKayttoohjeetHTMLExample'
import { Section } from '../../components/Section'
import { HeaderATMTExample } from '../../examples/react/HeaderATMTExample'
import { HeaderATMTWithManyOrganizationsExample } from '../../examples/react/HeaderATMTWithManyOrganizationsExample'
import { HeaderATMTWithSiteNotificationsExample } from '../../examples/react/HeaderATMTWithSiteNotificationsExample'
import { HeaderTMTExample } from '../../examples/react/HeaderTMTExample'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Header"
    components={[
      { component: Header },
      { component: HeaderATMT },
      { component: HeaderBackground },
      { component: HeaderButton },
      { component: HeaderSearch }
    ]}
  >
    <Section title={'TMT header'}>
      <Paragraph>
        Luokka <Code>header-asiointi</Code> vain Asioinnin sivuille.
      </Paragraph>
      <Playground
        enableOverflow
        example={HeaderAsiointiHTMLExample}
        format="html"
        widePreview
      />
    </Section>
    <Section title={'TMT Käyttöohjeet'}>
      <Playground
        enableOverflow
        example={HeaderKayttoohjeetHTMLExample}
        format="html"
        widePreview
      />
    </Section>
    <Section title={'A-TMT header'}>
      <Paragraph>
        Käyttäjälle, jolla on vain yksi organisaatio, organisaatiotietoa ei
        näytetä painikkeessa, vaan avautuvassa valikossa.
      </Paragraph>

      <Playground
        enableOverflow
        example={HeaderATMTExample}
        widePreview
      />

      <Paragraph>
        Käyttäjälle, jolla on useampi organisaatio, on olennaista näyttää
        valittuna oleva organisaatio painikkeessa. Organisaation voi vaihtaa
        valikon kautta. Valintaan käytetään Organisaatiovalinta-modaalia.
      </Paragraph>

      <Playground
        enableOverflow
        example={HeaderATMTWithManyOrganizationsExample}
        widePreview
      />

      <Paragraph>
        Navigaatio, jonka yläpuolella näytetään ilmoituspalkki (
        <Code>
          <GatsbyLink page="SiteNotifications" />
        </Code>
        ).
      </Paragraph>
      <Playground
        enableOverflow
        example={HeaderATMTWithSiteNotificationsExample}
        widePreview
      />
    </Section>
    <Section title={'Yleinen Header-komponentti'}>
      <Paragraph>
        Esimerkin mukainen <Code>Header</Code>-komponentti on käytössä vain
        tietyillä erikoissivuilla Työmarkkinatorilla.
      </Paragraph>
      <Playground
        enableOverflow
        example={HeaderTMTExample}
        widePreview
      />
    </Section>
    <Section title="Saavutettavuus (Verkkopalvelu)">
      <Paragraph>
        1-tason <Code>nav</Code>-elementille annetaan nimi{' '}
        <Code>aria-label</Code>illa (&quot;Päänavigaatio&quot;). 2-tason
        navigaatio linkitetään ylätason valittuun elementtiin:{' '}
        <Code>
          &lt;nav class=&quot;secondary-nav&quot;
          aria-labelledby=&quot;level-1-nav-item-x&quot;&gt;
        </Code>
      </Paragraph>
      <Paragraph>
        Jos vain 1-tason navigaatiosta on valittu sivu, sille merkitään{' '}
        <Code>aria-current=page</Code>. Jos myös 2-tason navigaatiosta on
        valittu sivu, 1-tason navigaation sivu merkitään{' '}
        <Code>aria-current=true</Code> ja 2-tason valittu sivu merkitään{' '}
        <Code>aria-current=page</Code>.
      </Paragraph>
    </Section>
  </Content>
)

export default Page
